import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageWithCaption = makeShortcode("ImageWithCaption");
const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const GetInTouch = makeShortcode("GetInTouch");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ImageWithCaption imageName="toast-overview" type="png" alt="A toast message at the bottom of a screen reading 'Heinz Baked Beans added to trolley'" mdxType="ImageWithCaption" />
    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-toast"
            }}>{`How to use toast`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#variants-of-toast"
            }}>{`Variants of toast`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#behaviour-of-toast"
            }}>{`Behaviour of toast`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#usage-guidelines"
            }}>{`Usage guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#content-guidelines"
            }}>{`Content guidelines`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#accessibility"
            }}>{`Accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <hr></hr>
    <h2 {...{
      "id": "how-to-use-toast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-toast",
        "aria-label": "how to use toast permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use toast`}</h2>
    <p>{`Toasts are short messages which can be used to give time-sensitive notifications or to display feedback that acknowledges an action.`}</p>
    <p><strong parentName="p">{`Use a toast:`}</strong></p>
    <ul>
      <li parentName="ul">{`For time-sensitive live notifications.`}</li>
      <li parentName="ul">{`To display feedback that acknowledges an action.`}</li>
    </ul>
    <p><strong parentName="p">{`Don't use a toast:`}</strong></p>
    <ul>
      <li parentName="ul">{`When providing users with a message related to a specific element on the page. We recommend using an 'Alert' instead.`}</li>
      <li parentName="ul">{`When a user is required to make a choice, such as 'Confirm' or 'Cancel'. We recommend using a 'Modal' instead.`}</li>
      <li parentName="ul">{`When a page already has multiple overlaying items, for example a modal or drawer.`}</li>
      <li parentName="ul">{`To convey a long message, we recommend an 'Alert' instead.`}</li>
      <li parentName="ul">{`To share a message of high importance, we recommend an 'Alert' instead.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "variants-of-toast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#variants-of-toast",
        "aria-label": "variants of toast permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Variants of toast`}</h2>
    <h3>{`System`}</h3>
    <p>{`System toasts are neutral and they can use any icon to further depict the message you are trying to convey. They are for communicating a message that doesn't fall into the other semantic categories, such as a status update from the system.`}</p>
    <ImageWithCaption imageName="toast-variant-system" type="png" alt="A grey toast message that reads 'Verifying link'" mdxType="ImageWithCaption" />
    <h3>{`Success`}</h3>
    <p>{`Success toasts are associated with the semantic green colour and use the tick icon. They are used for acknowledging that an action that a user has taken has been successful.`}</p>
    <ImageWithCaption imageName="toast-variant-success" type="png" alt="A green toast message that reads ''Heinz Baked Beans' added to trolley'" mdxType="ImageWithCaption" />
    <h3>{`Error`}</h3>
    <p>{`Error toasts are associated with the semantic colour red and use the error icon. They're used to communicate issues with the system or service to the user, and are typically used in situations where the user's not able to progress.`}</p>
    <ImageWithCaption imageName="toast-variant-error" type="png" alt="A red toast message that reads 'Sorry, that didn't work. Please try again later'" mdxType="ImageWithCaption" />
    <h3>{`Warning`}</h3>
    <p>{`Warning toasts are associated with the semantic colour yellow and use the warning icon. They're used to communicate issues with the system or service, but with a lower level of emphasis than errors. For example, the user is usually still able to progress.`}</p>
    <ImageWithCaption imageName="toast-variant-warning" type="png" alt="A yellow toast message that reads 'Sorry, that didn't work. Please try again later'" mdxType="ImageWithCaption" />
    <h3>{`Info`}</h3>
    <p>{`Information toasts are associated with the semantic colour blue and use the information icon. They are used to communicate help when a user trying to complete a task, but don't require any action from the user in order to progress.`}</p>
    <ImageWithCaption imageName="toast-variant-info" type="png" alt="A blue toast message that reads 'A new task has been assigned to you' and an 'Undo' link button" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "behaviour-of-toast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#behaviour-of-toast",
        "aria-label": "behaviour of toast permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Behaviour of toast`}</h2>
    <h3>{`Dismissable`}</h3>
    <p>{`Dismissible toasts include a close action so that users can choose to dismiss them.`}</p>
    <ImageWithCaption imageName="toast-dismissable" type="png" alt="A green toast message that reads '‘Heinz Baked Beans’ added to trolley' and a close icon button sits beside the message" mdxType="ImageWithCaption" />
    <h3>{`Toast with action`}</h3>
    <p>{`The toast can have one action, in addition to dismiss. This is often used to convey actions that can be undone, resolved or changed. `}</p>
    <ImageWithCaption imageName="toast-action" type="png" alt="A green toast message that reads '‘Heinz Baked Beans’ added to trolley' and an 'Undo' link button sits beside the message" mdxType="ImageWithCaption" />
    <h3>{`Toast with icon`}</h3>
    <p>{`Toast can be presented with or without an icon. When using the semantic variants of the toast notification, their icon selection is fixed to it's semantic meaning. For example, success toast displays a check icon.`}</p>
    <ImageWithCaption imageName="toast-icon" type="png" alt="A green toast message that reads '‘Heinz Baked Beans’ added to trolley' and an white tick icon sits beside the message" mdxType="ImageWithCaption" />
    <h3>{`Wrapping`}</h3>
    <p>{`When the toast is too long for the horizontal viewport, it wraps to form another line.`}</p>
    <ImageWithCaption imageName="toast-wrapping" type="png" alt="A red toast message that reads 'Sorry, that didn't work. Please try again later' sits at the bottom of a device and wraps to form two lines" mdxType="ImageWithCaption" />
    <h3>{`Placement`}</h3>
    <p>{`Toast can slide into view from a number of positions: `}<strong parentName="p">{`top-left`}</strong>{`, `}<strong parentName="p">{`top-centre`}</strong>{`, `}<strong parentName="p">{`top-right`}</strong>{`, `}<strong parentName="p">{`bottom-left`}</strong>{`, `}<strong parentName="p">{`bottom-centre`}</strong>{` and `}<strong parentName="p">{`bottom-right`}</strong>{`. All of which are offset by 16px from the nearest edge of the viewport. By default, bottom-centre is applied to the toast and so it's offset 16px from the bottom of the viewport.`}</p>
    <p>{`Throughout a product or service, we recommend keeping the position of the toast `}<strong parentName="p">{`consistent`}</strong>{`. This is to avoid disrupting the user experience by providing a consistent point of reference for all kinds of toast notifications, and considers when multiple toasts are triggered to form a stack.`}</p>
    <ImageWithCaption imageName="toast-placement" type="png" alt="Six screens are presented with the toast positioned in the top-left, top-centre, top-right, bottom-left, bottom-centre, and bottom-right" mdxType="ImageWithCaption" />
    <h3>{`Size`}</h3>
    <p>{`A toast's width is fixed to 320px for most breakpoints, regardless of it's position. On smaller devices (`}<strong parentName="p">{`Smaller than 321px`}</strong>{`, the toast becomes fluid and occupies 100% of the viewport width. `}</p>
    <ImageWithCaption imageName="toast-size" type="png" alt="A mobile, tablet and desktop screen displays a toast at a fixed width at the bottom of the viewport" mdxType="ImageWithCaption" />
    <h3>{`Stacking`}</h3>
    <p>{`When multiple toasts are triggered in a close time proximity, they stack with 8px in-between them. New toasts should appear at the top of the list, with older notifications being pushed down until they timeout or are dismissed.
Stacking is an optional behaviour, so if an experience does not require stacking each toast will replace the previous.`}</p>
    <h3>{`Duration`}</h3>
    <p>{`The duration of the toast depends on how it's configured:`}</p>
    <p>{`By default, toast automatically disappears after `}<strong parentName="p">{`5,000 milliseconds`}</strong>{` (5 seconds).
Duration can be tailored to your needs by updating the 'Duration' property. As a “safe” duration, we recommend `}<strong parentName="p">{`30 milliseconds for 1 word`}</strong>{` which takes into account the average reading speed of 200 words per minute (wpm).
While hovering over a toast, the duration is paused (refer to the Accessibility section for more details).`}</p>
    <hr></hr>
    <h2 {...{
      "id": "usage-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage-guidelines",
        "aria-label": "usage guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Usage guidelines`}</h2>
    <h3>{`Use semantic colour`}</h3>
    <p>{`Use the different semantic styles of toast to communicate the tone of the content within the toast visually.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <ImageWithCaption imageName="toast-semantic-do" type="png" alt="A green toast message at the bottom of a screen reading ''Heinz Baked Beans' added to trolley'" mdxType="ImageWithCaption" />
  </Do>
  <Dont center mdxType="Dont">
    <ImageWithCaption imageName="toast-semantic-dont" type="png" alt="A red toast message at the bottom of a screen reading ''Heinz Baked Beans' added to trolley'" mdxType="ImageWithCaption" />
  </Dont>
    </Guideline>
    <h3>{`Don't override the position`}</h3>
    <p>{`Toasts are always fixed to the position property it has been set. By keeping the position of the toast consistent, it reduces the cognitive load for the user and becomes an expected home for all toast notification messages. This is further supported by `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#consistent-identification"
      }}>{`WCAG's Guideline 3.2.4 Consistent Identification`}</a>{`.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <ImageWithCaption imageName="toast-position-do" type="png" alt="A toast message positioned at the bottom-centre of the screen" mdxType="ImageWithCaption" />
  </Do>
  <Dont center mdxType="Dont">
    <ImageWithCaption imageName="toast-position-dont" type="png" alt="A toast message in a custom position on the screen" mdxType="ImageWithCaption" />
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "content-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#content-guidelines",
        "aria-label": "content guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Content guidelines`}</h2>
    <h3>{`Write in sentence case`}</h3>
    <p>{`Toasts should ideally be only a single sentence, without a period at the end of it. This helps to keep the text quicker to read and interpret.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <ImageWithCaption imageName="toast-content-do" type="png" alt="A toast message that reads 'Your delivery slot has been changed'" mdxType="ImageWithCaption" />
  </Do>
  <Dont center mdxType="Dont">
    <ImageWithCaption imageName="toast-content-dont" type="png" alt="A blue toast message that reads 'WOO HOO! Your Delivery Slot Has Been Changed.'" mdxType="ImageWithCaption" />
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Accessibility`}</h2>
    <h3>{`Aria`}</h3>
    <p>{`The toast should utilise the 'polite' `}<strong parentName="p">{`aria-alert`}</strong>{` attribute, this will trigger certain assistive technologies, such as screen readers, to announce the content at a convenient moment after the toast appears.`}</p>
    <h3>{`Focus Considerations`}</h3>
    <p>{`When keyboard focus is placed on buttons in a toast, any auto-dismiss behaviour should be paused in order to meet `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#enough-time"
      }}>{`WCAG's Guideline 2.2 Enough Time`}</a>{`.`}</p>
    <p>{`When you activate a toast and the contents of the toast are announced to the user, the focus should also move to the first interact-able element of the toast (like the action or close buttons). When you close the toast, focus should return back to the element that opened the toast. This too is applied when the screen reader is reading the toast message at the next convenient moment (see Aria section for more details).`}</p>
    <h3>{`Keyboard Navigation`}</h3>
    <p>{`Keyboard navigation is important for helping users who do not use a mouse or pointer device. All interactive elements like links, buttons and controls must be able to receive focus and be operable using standard keyboard controls.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: Moves focus to next focusable element inside the toast. When focus is on the last focusable element in the toast, moves focus to the first focusable element in the toast.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Shift`}</inlineCode>{`+`}<inlineCode parentName="li">{`Tab`}</inlineCode>{`: Moves focus to previous focusable element inside the toast. When focus is on the first focusable element in the toast, moves focus to the last focusable element in the toast.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Spacebar`}</inlineCode>{`+`}<inlineCode parentName="li">{`Enter`}</inlineCode>{`: If focus is on the close button, it dismisses the toast. If the focus is on an action, it executes the button action.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Escape`}</inlineCode>{`: Closes the toast.`}</li>
    </ul>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using toast or give you a helping
  hand with your next project.
    </GetInTouch>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      